.upload-video-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.video-upload-form {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
}

.transcode-profile-card,
.supported-formats-card {
  margin-bottom: 20px;
}

.transcode-profile-card ul {
  padding-left: 20px;
}

.transcode-profile-card ul li {
  list-style: disc;
  margin-bottom: 5px;
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.upload-header h1 {
  font-size: 32px;
  font-weight: bold;
}

.manage-uploads-btn {
  background-color: #ffd700;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
}

.manage-uploads-btn:hover {
  background-color: #f0c300;
}

.upload-video-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.upload-header {
  display: flex;
  justify-content: flex-start; /* space-between에서 flex-start로 변경 */
  align-items: center;
  margin-bottom: 24px;
  padding: 0; /* 패딩 제거 */
}

.video-upload-form {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-top: 0; /* 상단 마진 제거 */
}
