.thumbnail-preview-container {
  display: flex;
  width: 30%;
  flex-direction: column;
  border: 1px solid #ddd; /* 회색 테두리 추가 */
  border-radius: 4px; /* 모서리 둥글게 */
  padding: 10px; /* 내부 여백 */
}

.thumbnail-preview-container > span {
  margin-bottom: 10px;
}

.thumbnail-uploaded-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ddd; /* 회색 테두리 추가 */
  border-radius: 4px; /* 모서리 둥글게 */
  padding: 10px; /* 내부 여백 */
  margin-left: 10px;
}

.thumbnail-uploaded-container > button {
  margin-top: 10px;
}

.thumbnail-file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.thumbnail-uploaded-container {
  flex: 1; /* 부모 flex 컨테이너의 남은 공간을 차지 */
  min-width: 0; /* flex 환경에서 넘침 방지 */
}
