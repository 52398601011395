body,
html {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

#root {
  height: 100%;
}

/* Sidebar.css */
.menu-header {
  font-size: 18px !important;
  font-weight: bold !important;
  color: white !important;
  margin-top: 20px !important;
  padding-left: 24px !important;
  border-top: 2px solid #fff !important;
  padding-bottom: 10px !important;
}

.content-menu-header {
  font-size: 18px !important;
  font-weight: bold !important;
  color: white !important;
  margin-top: 20px !important;
  padding-left: 24px !important;
}

.contract-menu-header {
  font-size: 18px !important;
  font-weight: bold !important;
  color: white !important;
  margin-top: 30px !important;
  border-top: 2px solid #fff !important;
  padding-top: 30px !important;
  padding-left: 24px !important;
}

.revenue-menu-header {
  font-size: 18px !important;
  font-weight: bold !important;
  color: white !important;
  margin-top: 30px !important;
  border-top: 2px solid #fff !important;
  padding-top: 30px !important;
  padding-left: 24px !important;
}

.ant-layout-sider.sider {
  overflow: auto !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  scrollbar-width: thin !important;
  scrollbar-color: unset !important;
  z-index: 1 !important;
  background-color: #001529 !important;
}

.sider .logo {
  height: 40px !important;
  width: 90% !important;
  margin: 20px auto !important;
  display: block !important;
}

.sider .divider {
  color: white !important;
  font-size: 18px !important;
  font-weight: bold !important;
  padding-left: 20px !important;
}

.ant-menu.menu {
  margin-top: 20px !important;
  background-color: #001529 !important;
  padding-left: 0 !important;
}

.ant-layout.layout-content {
  position: fixed !important;
  width: calc(100% - 200px) !important;
  margin-left: 200px !important;
  min-height: 0 !important;
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}

.ant-layout-content.content {
  margin: 10px 10px 0 20px !important;
  overflow: auto !important;
  flex: 1 !important;
  background-color: white !important;
}

.ant-layout-footer.footer {
  text-align: center !important;
  padding: 10px !important;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

.permission-menu-header {
  margin-top: 30px !important;
  border-top: 2px solid #fff !important;
  padding-top: 30px !important;
  padding-left: 24px !important;
}

.permission-menu-header > div {
  color: white !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.logo-button {
  width: 80% !important; /* 컨테이너의 90% 너비 유지 */
  height: auto !important; /* 높이는 자동으로 계산되어 비율 유지 */
  max-height: 100px !important; /* 최대 높이 제한 */
  margin: 20px auto !important;
  display: block !important;
}
